import React from 'react';
import { ServiceHOCPropsType, StateForService } from '../../data';
import { useStateForServices } from '../';

export class TemporaryStatesService {
  constructor(private _currentlyHoveredSuggestion: StateForService<string>, private _currentlyHoveredPlaylistTrack: StateForService<string>) {}
  /* PUBLIC METHODS FOR SETTING SERVICE STATE*/
  public get currentlyHoveredSuggestion(): string {
    return this._currentlyHoveredSuggestion.value;
  }

  public updateCurrentlyHoveredSuggestion(value: string): void {
    if (this._currentlyHoveredSuggestion.value !== value) {
      this._currentlyHoveredSuggestion.update(value);
    }
  }

  public get currentlyHoveredPlaylistTrack(): string {
    return this._currentlyHoveredPlaylistTrack.value;
  }

  public updateCurrentlyHoveredPlaylistTrack(value: string): void {
    if (this._currentlyHoveredPlaylistTrack.value !== value) {
      this._currentlyHoveredPlaylistTrack.update(value);
    }
  }
}

export const TemporaryStatesServiceContext = React.createContext<TemporaryStatesService | undefined>(undefined);

export const TemporaryStatesServiceHOC = (props: ServiceHOCPropsType) => {
  const service = new TemporaryStatesService(useStateForServices<string>(''), useStateForServices<string>(''));

  return <TemporaryStatesServiceContext.Provider value={service}>{props.children}</TemporaryStatesServiceContext.Provider>;
};
