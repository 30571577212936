import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { config } from '../../../../environments/config';
import { AuthServiceContext } from '../../../../services';
import { InstructionItem } from './components';
import InstructionImage1 from '../../../../assets/images/instruction1.png';
import InstructionImage2 from '../../../../assets/images/instruction2.png';
import './index.scss';

export type InstructionsPropsType = {};

export const Instructions = (props: InstructionsPropsType) => {
  const authService = useContext(AuthServiceContext);
  if (!authService) throw new Error('Missing context');

  const router = useHistory();
  const createPlaylist = (): void => {
    router.push(config.routes.playlistSources);
  };

  return (
    <div className="instructions-container d-flex flex-wrap">
      <InstructionItem
        classToDisplay="width-playlist-builder"
        imgSource={InstructionImage1}
        text="To create your first Isofi playlist, first select your playlist source from the button below. Once you have completed this, your playlist source will
        appear on the menu on the left-hand side."
        title="1. Playlist Builder">
        <div className="button-container">
          <Button className="section-text-sm1" variant="primary" onClick={() => authService.onlyAuthenticated(createPlaylist)}>
            Create from playlist sources
          </Button>
        </div>
      </InstructionItem>

      <InstructionItem
        classToDisplay="width-closes-track"
        imgSource={InstructionImage2}
        title="2. Closest Tracks"
        text="Once your playlist sources have been loaded, a graph should appear above. Click anywhere in the graph to reveal your first track. The 5 closest
        tracks in proximity to your plot will also be listed here."
      />
    </div>
  );
};
