import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { MapperService, PlaylistServiceContext } from '../../../services';
import './index.scss';

export const SharePlaylistModal = () => {
  const playlistService = useContext(PlaylistServiceContext);

  if (!playlistService) throw new Error('Context missing');

  const [linkCopied, setLinkCopied] = useState(false);

  const copyLink = () => {
    setLinkCopied(true);
    const inputElement = document.getElementById('playlist-id') as HTMLInputElement;
    inputElement.select();
    document.execCommand('Copy');

    setTimeout(() => {
      setLinkCopied(false);
    }, 500);
  };

  return (
    <div className="share-playlist-modal-container d-flex flex-column align-items-center justify-content-between">
      <div className="playlist-modal-title article-text-lg3">Use the link below to share Isofi playlist</div>
      <div className="playlist-modal-subtitle article-text-lg2">
        <div className="subtitle-item">When clicked, Isofi playlist will open in browser and in Spotify app if found on device</div>
        <div className="subtitle-item">Link is reusable so you can share it with anyone you'd like</div>
      </div>
      <div className="playlist-modal-body d-flex flex-column align-items-center">
        <input
          id="playlist-id"
          className={`${linkCopied ? 'copied' : ''}`}
          readOnly
          value={linkCopied ? 'Copied' : MapperService.mapSpotifyPlaylistIDToShareLink(playlistService.currentIsofiPlaylist.id)}
        />
        <Button onClick={copyLink} variant="primary">
          Copy link
        </Button>
      </div>
    </div>
  );
};
