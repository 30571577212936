import React from 'react';
import { AppStatesEnum, BackgroundFetchStateEnum, BackgroundPlaylistFetchModel, ModalEnums, ServiceHOCPropsType, StateForService } from '../../data';
import { useStateForServices } from '../index';

export class AppStatesService {
  constructor(
    private _appState: StateForService<AppStatesEnum>,
    private _backgroundFetch: StateForService<BackgroundPlaylistFetchModel>,
    private _modal: StateForService<string>,
    private _playlistsLoaded: StateForService<boolean>,
  ) {}
  /* PUBLIC METHODS FOR SETTING SERVICE STATE*/
  public get appState(): AppStatesEnum {
    return this._appState.value;
  }

  public updateAppState(value: AppStatesEnum): void {
    this._appState.update(value);
  }

  public get backgroundFetch(): BackgroundPlaylistFetchModel {
    return this._backgroundFetch.value;
  }

  public updateBackgroundFetch(value: BackgroundPlaylistFetchModel): void {
    this._backgroundFetch.update(value);
  }

  public get modal(): string {
    return this._modal.value;
  }

  public updateModal(value: string): void {
    this._modal.update(value);
  }

  public get playlistsLoaded(): boolean {
    return this._playlistsLoaded.value;
  }

  public updatePlaylistsLoaded(value: boolean): void {
    this._playlistsLoaded.update(value);
  }
}

export const AppStatesServiceContext = React.createContext<AppStatesService | undefined>(undefined);

export const AppStatesServiceHOC = (props: ServiceHOCPropsType) => {
  const service = new AppStatesService(
    useStateForServices<AppStatesEnum>(AppStatesEnum.LOADING),
    useStateForServices<BackgroundPlaylistFetchModel>(new BackgroundPlaylistFetchModel(BackgroundFetchStateEnum.INACTIVE)),
    useStateForServices<string>(ModalEnums.NO_MODAL),
    useStateForServices<boolean>(false),
  );

  return <AppStatesServiceContext.Provider value={service}>{props.children}</AppStatesServiceContext.Provider>;
};
