import { useState } from 'react';
import { StateForService, TrackModel } from './../../data';

/**
 * Checks array of tracks for duplicate based on id
 * @param trackID ID of the track to check
 * @param tracks Array of tracks to check
 * @returns boolean whether duplicate has been found or not
 */
export function checkForDuplicateTracks(trackID: string, tracks: TrackModel[]): boolean {
  for (const track of tracks) {
    if (track.id === trackID) {
      return true;
    }
  }

  return false;
}

/**
 * Splits one array into multiple smaller ones
 * @param chunkSize Number of elements in a smaller array
 * @param array Array to split
 * @returns Returns new array with smaller array inside
 */
export function createChunks(chunkSize: number, array: any[]): any[] {
  let finalArray: any[] = [];

  for (let i = 0; i < array.length; i += chunkSize) {
    finalArray.push(array.slice(i, i + chunkSize));
  }

  return finalArray;
}

/**
 * Returns pop-up centered style
 * @returns style string
 */
export function getPopUpParams(): string {
  return `width=${window.innerWidth * (1 / 2)},height=${window.innerHeight - 100})
    },top=${100},left=${window.innerWidth * (1 / 4)}`;
}

/**
 * Function that creates React useState pair (value + setter )
 * @param defaultValue Default value for state
 * @returns returns React use state pair
 */
export function useStateForServices<T>(defaultValue: T): StateForService<T> {
  const [value, update] = useState<T>(defaultValue);
  return {
    value,
    update,
  };
}
