import React, { ChangeEventHandler, Fragment } from 'react';
import './index.scss';

export type CustomCheckboxPropsType = {
  checked: boolean;
  change: ChangeEventHandler<HTMLInputElement>;
};

export const CustomCheckbox = (props: CustomCheckboxPropsType) => {
  return (
    <Fragment>
      <input className="hidden-checkbox" type="checkbox" checked={props.checked} onChange={props.change} />
      <div className="custom-checkbox"></div>
    </Fragment>
  );
};
