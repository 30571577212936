import React, { Fragment } from 'react';
import { BsFillPauseFill, BsPlayFill } from 'react-icons/bs';
import { AccountTypeEnum, PlayLocationEnum, TrackModel } from '../../../data';
import './index.scss';

export type PlayTrackButtonPropsType = {
  accountType: AccountTypeEnum;
  directHover: boolean;
  currentlyPlayingTrack: TrackModel | undefined;
  currentlyHoveredTrack: string;
  currentlyPlayingLocation: PlayLocationEnum;
  index: number;
  location: PlayLocationEnum;
  isPlaying: boolean;
  playTrack: Function;
  showZero: boolean;
  track: TrackModel;
  togglePlayback: Function;
};

export const PlayTrackButton = (props: PlayTrackButtonPropsType) => {
  const getIndex = (): string => {
    let indexString = '';
    if (props.showZero && props.index < 9) {
      indexString += '0';
    }

    indexString += props.index + 1;

    return indexString;
  };

  return (
    <Fragment>
      {props.accountType === AccountTypeEnum.PREMIUM &&
      props.currentlyPlayingTrack?.id === props.track.id &&
      props.currentlyPlayingLocation === props.location ? (
        props.isPlaying ? (
          props.currentlyHoveredTrack === props.track.id ? (
            <BsFillPauseFill onClick={() => props.togglePlayback(false)} className="paused" />
          ) : (
            <BsPlayFill className="playing" />
          )
        ) : props.currentlyHoveredTrack === props.track.id ? (
          <BsPlayFill onClick={() => props.togglePlayback(true)} className="playing" />
        ) : (
          <BsFillPauseFill className="paused" />
        )
      ) : props.currentlyHoveredTrack === props.track.id && props.accountType === AccountTypeEnum.PREMIUM && props.directHover ? (
        <BsPlayFill onClick={() => props.playTrack(props.track, props.location)} className="play-track" />
      ) : (
        <span>{getIndex()}</span>
      )}
    </Fragment>
  );
};
