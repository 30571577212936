import { SpotifyPlaylistModel, BasicFilterPresetEnum, ChartValueType, TrackModel, TrackMetadataModel } from '../../data';

export class Mapper {
  /**
   * Maps Track metadata key to alias
   * @param key Track metadata key
   * @returns Returns axis name
   */
  public mapSpotifyMetadataValueToAlias = (key: keyof TrackMetadataModel): string => {
    switch (key) {
      case 'energy':
        return 'Energy';

      case 'valence':
        return 'Valence';

      case 'instrumentalness':
        return 'Instrumentalness';

      case 'acousticness':
        return 'Acousticness';

      case 'danceability':
        return 'Danceability';

      case 'liveness':
        return 'Liveness';

      case 'loudness':
        return 'Loudness';

      case 'key':
        return 'Key';

      case 'speechiness':
        return 'Speechiness';

      case 'tempo':
        return 'Tempo (BPM)';

      default:
        return 'Energy';
    }
  };

  /**
   * Returns an array of Chart value types (coordinates + track) from array of track models
   * @param tracks Array of tracks to map
   * @param keyX Value of x coordinate
   * @param keyY Value of y coordinate
   * @returns
   */
  public mapTracksToChartDataset = (tracks: TrackModel[], keyX: keyof TrackMetadataModel, keyY: keyof TrackMetadataModel): ChartValueType[] => {
    return tracks.map((track: TrackModel) => {
      return {
        x: track.metadata[keyX],
        y: track.metadata[keyY],
        song: track,
      };
    });
  };

  /**
   *
   * @param tracks Maps Track model to spotify uri format
   * @returns Array of mapped string uris
   */
  public mapTracksToSpotifyPlayerString(tracks: TrackModel[]): string[] {
    return tracks.map(track => 'spotify:track:' + track.id);
  }

  /**
   * Maps filter presets to corresponding track metadata values
   * @param preset Basic filter preset
   * @returns An object with x and y axis names
   */
  public mapAxisValuesFromPreset = (preset: BasicFilterPresetEnum): { [key: string]: keyof TrackMetadataModel } => {
    switch (preset) {
      case BasicFilterPresetEnum.DISTRACTED_FOCUSED:
        return {
          x: 'energy',
          y: 'valence',
        };

      case BasicFilterPresetEnum.CONFUSION_PEACE:
        return {
          x: 'danceability',
          y: 'liveness',
        };

      case BasicFilterPresetEnum.HOPELESS_HOPEFUL:
        return {
          x: 'speechiness',
          y: 'instrumentalness',
        };
      default:
        return {
          x: 'energy',
          y: 'valence',
        };
    }
  };

  /**
   * Maps spotify playlists from response to app Playlist Model
   * @param userPlaylistsResponse Spotify list of playlists response
   * @param playlistIDs IDs of playlist to fetch and udpate
   * @returns Returns array of mapped Playlist models
   */
  public mapCachedPlaylists(userPlaylistsResponse: SpotifyApi.PlaylistObjectSimplified[], playlistIDs: string[]): SpotifyPlaylistModel[] {
    const playlists: SpotifyPlaylistModel[] = [];

    for (const playlistIDtoFetch of playlistIDs) {
      for (const userSpotifyPlaylist of userPlaylistsResponse) {
        if (playlistIDtoFetch === userSpotifyPlaylist.id) {
          playlists.push(new SpotifyPlaylistModel(userSpotifyPlaylist));
          break;
        }
      }
    }

    return playlists;
  }

  /**
   * Maps Spotify playlist ID to share link which automatically opens spotify playlist when clicked
   * @param playlistID ID of the playlist to share
   * @returns Returns mapped string
   */
  public mapSpotifyPlaylistIDToShareLink(playlistID: string): string {
    return `https://open.spotify.com/playlist/${playlistID}?si=23678913891`;
  }
}

export const MapperService = new Mapper();
