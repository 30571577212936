import React from 'react';
import { CustomCheckbox } from '../../../../../../components';
import { IsofiPlaylistModel, SpotifyPlaylistModel } from '../../../../../../data';
import './index.scss';

export type SidebarPlaylistPropsType = {
  changeIsofiPlaylistSource: Function;
  changeExpandedPlaylistState: Function;
  checkIfSourceIsSelected: Function;
  currentlyEditingPlaylistID: string;
  isofiPlaylist: IsofiPlaylistModel;
  setActiveIsofiPlaylistAndNavigate: Function;
  spotifyPlaylists: SpotifyPlaylistModel[];
};

export const SidebarPlaylist = (props: SidebarPlaylistPropsType) => {
  return (
    <div className="playlist-sidebar-item section-text-sm4">
      <div
        className={`playlist-parent-item d-flex justify-content-between ${props.isofiPlaylist.id === props.currentlyEditingPlaylistID ? 'selected' : ''}`}
        onClick={() => props.setActiveIsofiPlaylistAndNavigate(props.isofiPlaylist.id)}>
        <div className="d-flex align-items-center playlist-name">
          <div
            className={`${props.isofiPlaylist.expanded ? 'expanded' : 'collapsed'} dropdown-icon-container`}
            onClick={event => props.changeExpandedPlaylistState(event, props.isofiPlaylist)}></div>
          <span>{props.isofiPlaylist.name}</span>
        </div>
      </div>
      <div className={`playlist-child-item ${props.isofiPlaylist.expanded ? 'expanded' : 'collapsed'}`}>
        {props.spotifyPlaylists.map(spotifyPlaylist => {
          return (
            <label className="single-source d-flex align-items-center" key={spotifyPlaylist.id}>
              <CustomCheckbox
                change={event => props.changeIsofiPlaylistSource(event, props.isofiPlaylist, spotifyPlaylist)}
                checked={props.checkIfSourceIsSelected(props.isofiPlaylist, spotifyPlaylist)}
              />
              <span className="section-text-sm4">{spotifyPlaylist.name}</span>
            </label>
          );
        })}
      </div>
    </div>
  );
};
