import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Fragment, useContext, useEffect } from 'react';
import { Header, Spinner, SpotifyPlayer } from './components';
import { Home, Login, Logout, PlaylistBuilder, Page404, PlaylistSources } from './pages';
import {
  AppStatesServiceContext,
  AuthServiceContext,
  PlaylistServiceContext,
  PrivateRoute,
  PlayerServiceContext,
  SpotifyProviderServiceContext,
} from './services';
import { AppStatesEnum, BackgroundFetchStateEnum, BackgroundPlaylistFetchModel, TokenModel } from './data';
import { config } from './environments/config';
import './App.scss';

export function App() {
  /* Set up contexts, throw error if any of them are not accessible */
  const appStatesService = useContext(AppStatesServiceContext);
  const authService = useContext(AuthServiceContext);
  const playerService = useContext(PlayerServiceContext);
  const playlistService = useContext(PlaylistServiceContext);
  const spotifyProviderService = useContext(SpotifyProviderServiceContext);

  if (!authService || !appStatesService || !playlistService || !playerService || !spotifyProviderService) throw new Error('Missing context!');

  /** Init function
  => Check if token exists and if it is valid
  => If user is authenticated load playlists from local storage
  => If playlists are found in local storage, fetch latest playlists data in background
  */
  useEffect(() => {
    if (appStatesService.appState === AppStatesEnum.LOADING) {
      appStatesService.updateAppState(AppStatesEnum.LOADED);
      const token = TokenModel.loadTokenFromLocalStorage();
      authService.whoAmI(token);
    } else if (appStatesService.appState === AppStatesEnum.USER_CHECKED) {
      if (authService.authenticated) {
        playlistService.loadPlaylistsFromLocalStorage();
        appStatesService.updatePlaylistsLoaded(true);
      }
      appStatesService.updateAppState(AppStatesEnum.READY);
    }
  }, [appStatesService, authService.authenticated, authService, playlistService]);

  /* Listen to Background fetch boolean and update data in background if necessary */
  useEffect(() => {
    if (appStatesService.backgroundFetch.state === BackgroundFetchStateEnum.INITIATED) {
      appStatesService.updateBackgroundFetch(new BackgroundPlaylistFetchModel(BackgroundFetchStateEnum.IN_PROGRESS));
      playlistService.fetchAndUpdateDataInBackground(appStatesService.backgroundFetch.ids);
    }
  });

  return (
    <Fragment>
      {/** For build
       *    <BrowserRouter basename={document.querySelector('router-base')?.getAttribute('href') || '/'}>
       */}
      <BrowserRouter>
        <div className="main-app-container">
          {appStatesService.appState === AppStatesEnum.READY ? (
            <Fragment>
              <Header />
              <Switch>
                <Route
                  exact
                  path={[
                    config.routes.home,
                    config.routes.about,
                    config.routes.contact,
                    config.routes.legal,
                    config.routes.privacy,
                    config.routes.termsAndConditions,
                  ]}
                  component={Home}></Route>
                <Route
                  exact
                  path={config.routes.playlistSources}
                  render={routeProps => (
                    <Home {...routeProps}>
                      <PrivateRoute authenticated={authService.authenticated} component={PlaylistSources} {...routeProps}></PrivateRoute>
                    </Home>
                  )}></Route>
                <Route
                  exact
                  path={config.routes.playlistBuilderFull}
                  render={routeProps => (
                    <Home {...routeProps}>
                      <PrivateRoute authenticated={authService.authenticated} component={PlaylistBuilder} {...routeProps}></PrivateRoute>
                    </Home>
                  )}></Route>
                <Route path={config.routes.login} component={Login}></Route>
                <Route path={config.routes.logout} component={Logout}></Route>
                <Route path={config.routes.wildCard} component={Page404} />
              </Switch>
            </Fragment>
          ) : (
            <Spinner />
          )}
        </div>
      </BrowserRouter>
      <SpotifyPlayer
        authService={authService}
        playerService={playerService}
        playlistService={playlistService}
        spotifyProviderService={spotifyProviderService}
      />
    </Fragment>
  );
}
