import React, { Fragment, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { IoIosArrowForward } from 'react-icons/io';
import { config } from '../../../../environments/config';
import { AppStatesServiceContext, AuthServiceContext, PlaylistServiceContext } from '../../../../services';
import { IsofiPlaylistModel, ModalEnums, PlaylistEnums, SpotifyPlaylistModel } from '../../../../data';
import { SidebarPlaylist } from './components';
import './index.scss';

export type LeftSidebarPropsType = {};

export const LeftSidebar = (props: LeftSidebarPropsType) => {
  const appStatesService = useContext(AppStatesServiceContext);
  const authService = useContext(AuthServiceContext);
  const playlistService = useContext(PlaylistServiceContext);

  if (!appStatesService || !authService || !playlistService) throw new Error('Missing context');
  const router = useHistory();

  const addPlaylistSource = (): void => {
    router.push(config.routes.playlistSources);
  };

  const changeIsofiPlaylistSource = (
    event: React.ChangeEvent<HTMLInputElement>,
    isofiPlaylist: IsofiPlaylistModel,
    spotifyPlaylist: SpotifyPlaylistModel,
  ): void => {
    if (event.target.checked) {
      playlistService.addSourceToIsofiPlaylist(isofiPlaylist, spotifyPlaylist);
    } else {
      playlistService.removeSourceFromIsofiPlaylist(isofiPlaylist, spotifyPlaylist);
    }
  };

  const changeExpandedPlaylistState = (event: React.MouseEvent<HTMLDivElement>, playlist: IsofiPlaylistModel): void => {
    event.preventDefault();
    event.stopPropagation();
    playlistService.changeExpandedPlaylistState(playlist);
  };

  const createPlaylist = (): void => {
    appStatesService.updateModal(ModalEnums.NEW_PLAYLIST);
  };

  const setActiveIsofiPlaylistAndNavigate = (playlistID: string): void => {
    playlistService.updateCurrentlyEditingPlaylist(playlistID);
    router.push(`${config.routes.playlistBuilderBase}/${playlistID}`);
  };

  return (
    <Fragment>
      <div className={`left-sidebar-container`}>
        <div className="container-resizing">
          <div className="container-fixed">
            <Link to="/" className="app-title">
              isofi
            </Link>
            <div className="content-container">
              <div className="content-item">
                <div className="title article-title-lg1">{authService.authenticated ? 'Client ' : ''}Playlists</div>
                <div className="playlist-buttons-container">
                  <div className="button-container d-flex align-items-center" onClick={() => authService.onlyAuthenticated(addPlaylistSource)}>
                    <div className="add-button"></div>
                    <span className="section-text-sm1">Manage playlist sources</span>
                  </div>
                  {playlistService.isofiPlaylists.length === 0 ? (
                    <div className="button-container d-flex align-items-center" onClick={() => authService.onlyAuthenticated(createPlaylist)}>
                      <div className="add-button"></div>
                      <span className="section-text-sm1">Create a new playlist</span>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="divider"></div>
              <div className="isofi-playlists-container">
                {playlistService.isofiPlaylists.map(isofiPlaylist => {
                  if (isofiPlaylist.id === playlistService.currentIsofiPlaylist.id) {
                    return (
                      <SidebarPlaylist
                        changeIsofiPlaylistSource={changeIsofiPlaylistSource}
                        changeExpandedPlaylistState={changeExpandedPlaylistState}
                        checkIfSourceIsSelected={playlistService.checkIfSourceIsSelected.bind(null, playlistService.isofiPlaylists)}
                        currentlyEditingPlaylistID={playlistService.currentIsofiPlaylist.id}
                        isofiPlaylist={isofiPlaylist}
                        key={isofiPlaylist.id}
                        setActiveIsofiPlaylistAndNavigate={setActiveIsofiPlaylistAndNavigate}
                        spotifyPlaylists={playlistService.isofiSources}
                      />
                    );
                  } else return null;
                })}
              </div>
            </div>

            {/* Currently hidden option to hide main left sidebar. Option to put it back to use for mobile
          <div className="caret-container" onClick={() => appStatesService.setSidebarOpen(!appStatesService.sidebarOpen)}>
            {appStatesService.sidebarOpen ? <IoIosArrowBack /> : <IoIosArrowForward />}
          </div> */}
            {authService.authenticated && playlistService.currentIsofiPlaylist.id !== PlaylistEnums.EMPTY_NAME ? (
              <div className="caret-container">
                <IoIosArrowForward />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
