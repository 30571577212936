import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { config } from '../../environments/config';
import { AppStatesServiceContext, AuthServiceContext, PlaylistServiceContext } from '../../services';
import './index.scss';

export const Logout = () => {
  const authService = useContext(AuthServiceContext);
  const appStatesService = useContext(AppStatesServiceContext);
  const playlistService = useContext(PlaylistServiceContext);

  if (!authService || !playlistService || !appStatesService) throw new Error('Missing context');

  const router = useHistory();
  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    if (!pageLoaded) {
      setPageLoaded(true);
      authService.logout();
      playlistService.logout();
      router.push(config.routes.home);
    }
  }, [pageLoaded, authService, router, playlistService, appStatesService]);
  return <div className="logout-container"></div>;
};
