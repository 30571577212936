import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { ModalWrapper } from '../../components';
import { AppStatesServiceContext, ModalService } from '../../services/';
import { Instructions, RightSidebar, LeftSidebar } from './components';
import './index.scss';

export type HomePropsType = {
  children?: React.ReactChild;
};

export const Home = (props: HomePropsType) => {
  const appStatesService = useContext(AppStatesServiceContext);

  if (!appStatesService) throw new Error('Missing context');

  const [pageLoaded, setPageLoaded] = useState(false);
  const router = useHistory();

  // Initial component load => set current route
  useEffect(() => {
    if (!pageLoaded) {
      setPageLoaded(false);
      if (ModalService.checkModalURL(router.location.pathname)) {
        appStatesService.updateModal(router.location.pathname);
      }
    }
  }, [pageLoaded, appStatesService, router.location.pathname]);

  return (
    <>
      <div className="home-container">
        <LeftSidebar />
        <div className={`body-container d-flex flex-row`}>
          <div className="main-content">{props.children ? props.children : <Instructions />}</div>
          <RightSidebar />
        </div>
      </div>

      {(() => {
        const modal = ModalService.checkModal(appStatesService.modal);
        if (modal) {
          return <ModalWrapper>{modal}</ModalWrapper>;
        }
      })()}
    </>
  );
};
