import React, { Fragment, useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { FaCaretUp, FaCaretDown } from 'react-icons/fa';
import { GiHamburgerMenu } from 'react-icons/gi';
import { config } from '../../../../environments/config';
import { AppStatesServiceContext, AuthServiceContext, PlaylistServiceContext } from '../../../../services';
import { ModalEnums } from '../../../../data';
import './index.scss';

export type MenuPropTypes = {};

export const Menu = (props: MenuPropTypes) => {
  const authService = useContext(AuthServiceContext);
  const appStatesService = useContext(AppStatesServiceContext);
  const playlistService = useContext(PlaylistServiceContext);
  if (!authService || !appStatesService || !playlistService) throw new Error('Missing context!');

  const router = useHistory();
  const [detailedShown, setDetailedShown] = useState(false);

  const menuAction = (event: React.MouseEvent, callback: Function): void => {
    event.preventDefault();
    setDetailedShown(false);
    callback();
  };

  const openModal = (modalRoute: string): void => {
    appStatesService.updateModal(modalRoute);
  };

  const menuLogin = (): void => {
    authService.authorize();
  };

  const menuLogout = (): void => {
    router.push(config.routes.logout);
  };

  const menuLoginWithAnotherAccount = (): void => {
    playlistService.logout();
    authService.loginWithAnotherAccount();
  };

  return (
    <div className="menu-container d-flex">
      <div className="menu-short d-flex justify-content-end align-items-center">
        {authService.authenticated ? (
          <div className="menu-clickable" onClick={() => setDetailedShown(!detailedShown)}>
            <img src={authService.user.profilePicture} alt="user-profile" />
            <span className="section-text-sm2">{authService.user.name}</span>
            {detailedShown ? <FaCaretUp /> : <FaCaretDown />}
          </div>
        ) : (
          <div className="login" onClick={() => setDetailedShown(!detailedShown)}>
            <span className="section-text-sm2">Menu</span>
            <GiHamburgerMenu />
          </div>
        )}
      </div>
      <div className={`menu-detailed dropdown-menu flex-column align-items-center ${detailedShown ? 'menu-opened' : 'menu-closed'}`}>
        <a href={ModalEnums.ABOUT} onClick={event => menuAction(event, openModal.bind(null, ModalEnums.ABOUT))} className="menu-option dropdown-item">
          About
        </a>
        <a href={ModalEnums.CONTACT_US} onClick={event => menuAction(event, openModal.bind(null, ModalEnums.CONTACT_US))} className="menu-option dropdown-item">
          Contact Us
        </a>
        <a href={ModalEnums.LEGAL} onClick={event => menuAction(event, openModal.bind(null, ModalEnums.LEGAL))} className="menu-option dropdown-item">
          Legal
        </a>
        <a href={ModalEnums.PRIVACY} onClick={event => menuAction(event, openModal.bind(null, ModalEnums.PRIVACY))} className="menu-option dropdown-item">
          Privacy
        </a>
        <a href={ModalEnums.TCS} onClick={event => menuAction(event, openModal.bind(null, ModalEnums.TCS))} className="menu-option dropdown-item">
          T's + C's
        </a>
        {authService.authenticated ? (
          <Fragment>
            <div className="menu-option" onClick={event => menuAction(event, menuLoginWithAnotherAccount)}>
              Login with another account
            </div>
            <div onClick={event => menuAction(event, menuLogout)} className="menu-option">
              Logout
            </div>
          </Fragment>
        ) : (
          <div onClick={event => menuAction(event, menuLogin)} className="menu-option">
            Login with Spotify
          </div>
        )}
      </div>
    </div>
  );
};
