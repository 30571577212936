import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Button } from 'react-bootstrap';
import { config } from '../../environments/config';
import { HelpMessage } from '../../components';
import { ClosestTracks, ScatterChart } from './components';
import { TrackModel } from '../../data';
import { PlayerServiceContext, PlaylistServiceContext, TemporaryStatesServiceContext } from '../../services';
import GeneralInteractionsWarning2 from '../../assets/icons/generalInteractionsWarning2.svg';
import GeneralInteractionsError1 from '../../assets/icons/generalInteractionsError1.svg';
import './index.scss';

export type PlaylistBuilderParams = {
  playlistID: string;
};

export const PlaylistBuilder = () => {
  const playlistService = useContext(PlaylistServiceContext);
  const playerService = useContext(PlayerServiceContext);
  const temporaryStatesService = useContext(TemporaryStatesServiceContext);

  if (!playlistService || !temporaryStatesService || !playerService) throw new Error('Missing context');

  const params = useParams<PlaylistBuilderParams>();
  const router = useHistory();
  const [currentParams, setCurrentParams] = useState(params.playlistID);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [resetChart, setResetChart] = useState(false);
  const [showHelpMessage, setShowHelpMessage] = useState(true);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [saveInProgressID, setSaveInProgressID] = useState('');

  const addSuggestionToPlaylist = async (track: TrackModel): Promise<void> => {
    setSaveInProgress(true);
    setSaveInProgressID(track.id);
    const response = await playlistService.addTrackToPlaylist(track);
    if (response) {
      setSaveInProgress(false);
      setSaveInProgressID('');
    }
  };

  const resetPlaylist = async (): Promise<void> => {
    const resetPlaylistResponse = await playlistService.resetPlaylist();

    if (resetPlaylistResponse) {
      setResetChart(true);
    }
  };

  useEffect(() => {
    if (!pageLoaded) {
      setPageLoaded(true);
      setCurrentParams(params.playlistID);
      playlistService.updateCurrentlyEditingPlaylist(params.playlistID);
    }
  }, [pageLoaded, params.playlistID, playlistService]);

  useEffect(() => {
    if (resetChart) {
      setResetChart(false);
    }
  }, [resetChart]);

  useEffect(() => {
    if (params.playlistID !== currentParams) {
      setCurrentParams(params.playlistID);
      playlistService.updateCurrentlyEditingPlaylist(params.playlistID);
      playlistService.updateSuggestions([]);
    }
  }, [params, currentParams, playlistService]);

  // If user has no isofi playlists, redirect him to home
  useEffect(() => {
    if (playlistService.isofiPlaylists.length === 0) {
      router.push(config.routes.home);
    }
  }, [playlistService.isofiPlaylists.length, router]);

  return (
    <div className="playlist-builder-container d-flex flex-column align-items-start">
      {showHelpMessage ? (
        <HelpMessage
          closeMessage={() => setShowHelpMessage(false)}
          icon={GeneralInteractionsWarning2}
          message="To find a track that match this playlist profile, plot points on the graph bellow."
        />
      ) : null}
      <div className="graph">
        <div className="info-and-controls d-flex justify-content-between">
          <div className="graph-title d-flex align-items-center">
            <span className="article-title-lg1">Playlist Builder</span>
            <img src={GeneralInteractionsError1} alt="info icon" />
          </div>
          <div className="graph-controls">
            <Button variant="primary" disabled className="section-text-sm3">
              Undo
            </Button>
            <Button variant="secondary" className="section-text-sm3" onClick={resetPlaylist}>
              Clear / Reset
            </Button>
          </div>
        </div>
        <div className="chart">
          <ScatterChart
            playerService={playerService}
            playlistService={playlistService}
            playlistID={currentParams}
            resetChart={resetChart}
            temporaryStatesService={temporaryStatesService}
          />
        </div>
      </div>

      <ClosestTracks addSuggestionToPlaylist={addSuggestionToPlaylist} saveInProgress={saveInProgress} saveInProgressID={saveInProgressID} />
    </div>
  );
};
