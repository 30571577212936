import React from 'react';
import { Spinner as BootstrapSpinner } from 'react-bootstrap';
import './index.scss';

export type SpinnerPropsType = {};

export const Spinner = (props: SpinnerPropsType) => {
  return (
    <div className="spinner-container d-flex align-content-center justify-content-center">
      <BootstrapSpinner animation="border" role="status" variant="light"></BootstrapSpinner>
    </div>
  );
};

export const IconSpinner = (props: SpinnerPropsType) => {
  return <BootstrapSpinner animation="border" role="status" variant="light"></BootstrapSpinner>;
};
