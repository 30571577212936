import React from 'react';
import { StaticModalWrapper } from '../index';

export const TermsAndConditionsModal = () => {
  return (
    <StaticModalWrapper title="Terms and conditions">
      <>
        Maecenas posuere, mi non blandit sagittis, quam turpis tristique leo, non laoreet metus mi quis odio. Vestibulum eu eleifend dolor, cursus aliquam eros.
        Nunc nec quam dolor. Suspendisse potenti. Ut arcu turpis, fermentum in consequat vel, fringilla eu mi. Nulla facilisi. Integer mollis pulvinar euismod.
        Duis mollis magna non felis consequat efficitur. Aenean aliquam turpis turpis, et gravida neque aliquet malesuada. Pellentesque vitae enim euismod,
        commodo lectus et, elementum mi. Cras augue libero, ullamcorper ac scelerisque ut, egestas ut nibh. Mauris justo justo, malesuada ut euismod ac, congue
        in nibh. Aliquam ultrices, felis eget lobortis finibus, quam turpis commodo dolor, eget varius metus tortor nec justo. Proin suscipit mauris et dolor
        imperdiet, in mattis nisl sagittis.
      </>
    </StaticModalWrapper>
  );
};
