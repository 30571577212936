import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Menu } from './components';
import './index.scss';

export type HeaderPropsType = {};

export const Header = (props: HeaderPropsType) => {
  return (
    <div className="header-container w-100">
      <Row className="h-100">
        <Col className="d-flex justify-content-between">
          <Link to="/" className="app-title d-flex align-content-center">
            isofi
          </Link>
          <Menu />
        </Col>
      </Row>
    </div>
  );
};
