export type ArtistObjectType = {
  name: string;
  id: string;
};

export class ArtistModel {
  constructor(artistObject: SpotifyApi.ArtistObjectSimplified | ArtistModel) {
    this.id = artistObject.id;
    this.name = artistObject.name;
  }

  public name: string;
  public id: string;
}
