import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { AppStatesServiceContext, AuthServiceContext, PlaylistServiceContext, PlayerServiceContext, TemporaryStatesServiceContext } from '../../../../services';
import { ModalEnums, PlaylistStatsModel, PlayLocationEnum, TrackModel } from '../../../../data';
import InstructionImage3 from '../../../../assets/images/instruction3.png';
import { InstructionItem } from '../Instructions/components';
import { SidebarTrack } from './components';
import './index.scss';

export type RightSidebarPropsType = {};

export const RightSidebar = (props: RightSidebarPropsType) => {
  const authService = useContext(AuthServiceContext);
  const appStates = useContext(AppStatesServiceContext);
  const playerService = useContext(PlayerServiceContext);
  const playlistService = useContext(PlaylistServiceContext);
  const temporaryStatesService = useContext(TemporaryStatesServiceContext);

  if (!appStates || !playlistService || !authService || !playerService || !temporaryStatesService) throw new Error('Missing context');

  const [directHover, setDirectHover] = useState(false);
  const [playlistStats, setPlaylistStats] = useState(new PlaylistStatsModel(0, 0, 0));
  const [previousLength, setPreviousLength] = useState(0);
  const [temporaryPlaylist, setTemporaryPlaylist] = useState<TrackModel[]>([]);

  const changeCurrentlyHoveredPlaylistTrack = (trackID: string) => {
    temporaryStatesService.updateCurrentlyHoveredPlaylistTrack(trackID);
    setDirectHover(true);
  };

  const handleOnMouseLeave = () => {
    setDirectHover(false);
    temporaryStatesService.updateCurrentlyHoveredPlaylistTrack('');
  };

  const playTrack = (track: TrackModel, location: PlayLocationEnum) => {
    playerService.playTrack(track, location);
  };

  const sharePlaylist = () => {
    appStates.updateModal(ModalEnums.SHARE_PLAYLIST);
  };

  const togglePlayback = (newPlaybackValue: boolean) => {
    if (playerService.isPlaying) {
      playerService.spotifyPlayer?.pause();
    } else {
      playerService.spotifyPlayer?.resume();
    }
    playerService.updateIsPlaying(newPlaybackValue);
  };

  useEffect(() => {
    if (playlistService.currentIsofiPlaylist?.tracks.length !== previousLength) {
      const newStats = new PlaylistStatsModel(0, 0, 0);
      let duration = 0;
      playlistService.currentIsofiPlaylist?.tracks.forEach(track => {
        newStats.numberOfSongs++;
        duration += track.metadata.duration_ms;
      });
      newStats.minutes = Math.floor(duration / 1000 / 60);
      newStats.seconds = Math.round((duration / 1000) % 60);

      setPreviousLength(newStats.numberOfSongs);
      setPlaylistStats(newStats);
      setTemporaryPlaylist(playlistService.currentIsofiPlaylist.tracks);
    }
  }, [previousLength, playlistService.currentIsofiPlaylist, playlistService.currentIsofiPlaylist?.tracks, playlistService.currentIsofiPlaylist?.tracks.length]);

  return (
    <div className="right-sidebar-container d-flex flex-column">
      <div className="top-container">
        <div className="title article-title-lg1">{playlistService.currentIsofiPlaylist?.name}</div>
        <div className="info section-text-sm1">
          <span>{`${playlistStats.numberOfSongs} ${playlistStats.numberOfSongs > 0 ? 'songs' : 'song'},`}</span>
          <span>{`${playlistStats.minutes} mins,`}</span>
          <span>{`${playlistStats.seconds} seconds`}</span>
        </div>
        <div className="share-button-container d-flex justify-content-center">
          <Button
            variant="primary"
            onClick={() => authService.onlyAuthenticated(sharePlaylist)}
            className="section-text-sm2"
            disabled={!authService.authenticated || !playlistService.isofiPlaylists.length}>
            Share playlist
          </Button>
        </div>
      </div>

      <div className="control-buttons-container d-flex">
        <div className="control-button section-text-sm1">Expand All</div>
        <div className="control-button section-text-sm1">Collapse All</div>
      </div>

      {temporaryPlaylist.length === 0 ? (
        <InstructionItem
          classToDisplay="width-playlist"
          imgSource={InstructionImage3}
          text="Once you decide on a song selected from the 'Closest Tracks' module on the left, press the 'Add' button. It will then appear here in your playlist
      for you to enjoy and share."
          title="3. Playlist"
        />
      ) : (
        <div className="playlist-tracks-container">
          {temporaryPlaylist.map((track, index) => {
            if (track) {
              return (
                <SidebarTrack
                  accountType={authService.user.product}
                  changeCurrentlyHoveredPlaylistTrack={changeCurrentlyHoveredPlaylistTrack}
                  currentlyPlayingTrack={playerService.currentlyPlayingTrack}
                  currentlyHoveredPlaylistTrack={
                    temporaryStatesService?.currentlyHoveredPlaylistTrack ? temporaryStatesService.currentlyHoveredPlaylistTrack : ''
                  }
                  currentlyPlayingLocation={playerService.currentlyPlayingLocation}
                  directHover={directHover}
                  handleOnMouseLeave={handleOnMouseLeave}
                  index={index}
                  isPlaying={playerService.isPlaying}
                  key={track.id}
                  playTrack={playTrack}
                  track={track}
                  togglePlayback={togglePlayback}
                />
              );
            } else return null;
          })}
        </div>
      )}
    </div>
  );
};
