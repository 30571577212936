import React, { Component } from 'react';
import { AuthService, PlaylistService, SpotifyProviderService } from '../../services';
import { ReactComponent as PlayerReplayLast5Seconds } from '../../assets/icons/playerReplayLast5Seconds.svg';
import { ReactComponent as PlayerPreviousSong } from '../../assets/icons/playerPreviousSong.svg';
import { ReactComponent as PlayerPlayFilled } from '../../assets/icons/playerPlayFilled.svg';
import { ReactComponent as PlayerNextSong } from '../../assets/icons/playerNextSong.svg';
import { ReactComponent as PlayerForward30Seconds } from '../../assets/icons/playerForward30Seconds.svg';
import { ReactComponent as PlayerPausedFilled } from '../../assets/icons/playerPausedFilled.svg';
import { ReactComponent as PlayerVolumeOn } from '../../assets/icons/playerVolumeOn.svg';
import { ReactComponent as PlayerVolumeOff } from '../../assets/icons/playerVolumeOff.svg';
import { PlayLocationEnum, SpotifyImageModel, TrackModel, AccountTypeEnum } from '../../data';
import { config } from '../../environments/config';
import { PlayerService } from '../../services/PlayerService';
import DefaultProfilePicture from '../../assets/images/defaultProfilePicture.jpg';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import './index.scss';

const SliderWithTooltip = createSliderWithTooltip(Slider);

interface ISpotifyPlayerProps {
  authService: AuthService;
  playerService: PlayerService;
  playlistService: PlaylistService;
  spotifyProviderService: SpotifyProviderService;
}

interface ISpotifyPlayerState {
  dragging: boolean;
  previousVolumeValue: number;
  spotifyLoaded: boolean;
  temporaryProgressValue: number;
}

export class SpotifyPlayer extends Component<ISpotifyPlayerProps, ISpotifyPlayerState> {
  constructor(props: ISpotifyPlayerProps) {
    super(props);
    this.state = {
      dragging: false,
      previousVolumeValue: 0.5,
      spotifyLoaded: false,
      temporaryProgressValue: 0,
    };
  }

  public componentDidMount() {
    // Only load spotify player if user is authenticated
    if (this.props.authService.authenticated) {
      this.initPlayer();
    }
  }

  public componentDidUpdate(prevProps: ISpotifyPlayerProps) {
    // Track if user has been authenticated after the mount => load player if he did
    if (!prevProps.authService.authenticated && this.props.authService.authenticated) {
      this.initPlayer();
    }

    // If player is no longer authenticated, hide player
    else if (prevProps.authService.authenticated && !this.props.authService.authenticated) {
      this.setState({ spotifyLoaded: false });
    }

    // If authentication token has changed, reinitialize Player
    else if (prevProps.authService.token.access_token !== this.props.authService.token.access_token) {
      console.log('token changed');
    }
  }

  /**
   * Remove Player SDK listeners to avoid memory leaks
   */
  public componentWillUnmount(): void {
    this.props.playerService.spotifyPlayer?.removeListener('ready');
    this.props.playerService.spotifyPlayer?.removeListener('not_ready');
    this.props.playerService.spotifyPlayer?.removeListener('player_state_changed');
  }

  public changeVolume = (value: number): void => {
    this.props.playerService.changeVolume(value);
  };

  public initPlayer(): void {
    if (this.props.authService.user.product === AccountTypeEnum.PREMIUM) {
      const script = document.createElement('script');
      script.src = 'https://sdk.scdn.co/spotify-player.js';
      script.async = true;
      document.body.appendChild(script);
      (window as any).onSpotifyWebPlaybackSDKReady = (d: any) => {
        const player = new (window as any).Spotify.Player({
          name: config.spotify.playerName,
          getOAuthToken: (callback: Function) => {
            callback(this.props.authService.token.access_token);
          },
          volume: this.props.playerService.volume,
        });

        player.addListener('ready', async (webPlaybackInstance: Spotify.WebPlaybackInstance) => {
          this.props.playerService.updateDeviceID(webPlaybackInstance.device_id);
          const transfer = await this.props.spotifyProviderService.transferPlaybackToIsofiPlayer({ device_ids: [webPlaybackInstance.device_id] });
          const currentlyPlayingTrack = await this.props.spotifyProviderService.getCurrentlyPlayingTrack();
          if (transfer.ok) {
            if (currentlyPlayingTrack.item.hasOwnProperty('id')) {
              if (this.props.playerService.checkCurrentlyPlayingTrackLocation(currentlyPlayingTrack.item.id)) {
                this.props.playerService.updateCurrentlyPlayingLocation(PlayLocationEnum.PLAYLIST);
              } else {
                this.props.playerService.updateCurrentlyPlayingLocation(PlayLocationEnum.NATIVE_APP);
              }
            }

            this.setState({ spotifyLoaded: true });
          }
        });

        player.addListener('not_ready', (deviceID: string) => {
          console.log('Device ID has gone offline', deviceID);
        });

        player.addListener('player_state_changed', (state: Spotify.PlaybackState) => {
          if (state) {
            // Synchronize local track and progress with Spotify
            if (
              state.track_window.current_track.id !== this.props.playerService.currentlyPlayingTrack?.id ||
              !state.paused !== this.props.playerService.isPlaying ||
              Math.abs(state.position - this.props.playerService.currentProgress) > 1000
            ) {
              this.props.playerService.updateIsPlaying(!state.paused, state.position);

              if (state.track_window.current_track.id !== this.props.playerService.currentlyPlayingTrack?.id) {
                this.props.playerService.updateCurrentlyPlayingTrack(new TrackModel(state.track_window.current_track as any));
              }

              if (state.paused && state.track_window.previous_tracks[0]?.id === this.props.playerService.currentlyPlayingTrack?.id) {
                this.props.playerService.initializeNextTrackManually();
              }
            }
          }
        });

        player.connect();
        this.props.playerService.updateSpotifyPlayer(player);
      };
    }
  }

  public startDragging = (value: number): void => {
    this.setState({ dragging: true, temporaryProgressValue: value });
  };

  public stopDraggingAndJumpToProgressPoint = (value: number) => {
    this.setState({ dragging: false, temporaryProgressValue: value }, () => {
      this.props.playerService.skipWithDebounce(value);
    });
  };

  public handleReplayOrForward(skipAmount: number): void {
    if (this.props.playerService.isPlaying && this.props.playerService.currentlyPlayingTrack) {
      const newProgressPoint =
        this.state.temporaryProgressValue < this.props.playerService.currentlyPlayingTrack.duration_ms
          ? this.state.temporaryProgressValue + skipAmount
          : this.props.playerService.currentlyPlayingTrack.duration_ms;
      this.setState(
        {
          temporaryProgressValue: newProgressPoint,
        },
        () => {
          this.props.playerService.skipWithDebounce(newProgressPoint);
        },
      );
    }
  }

  public setVolumeByClick(mute: boolean): void {
    if (mute) {
      this.setState({ previousVolumeValue: this.props.playerService.volume });
      this.props.playerService.changeVolume(0);
    } else {
      this.props.playerService.changeVolume(this.state.previousVolumeValue);
    }
  }

  public togglePlayback = () => {
    if (this.props.playerService.spotifyPlayer && this.props.playerService.currentlyPlayingTrack) {
      this.props.playerService.spotifyPlayer.getCurrentState().then((state: Spotify.PlaybackState | null) => {
        if (state) {
          if (state.paused) {
            this.props.playerService.spotifyPlayer?.resume();
          } else {
            this.props.playerService.spotifyPlayer?.pause();
          }

          this.props.playerService.updateIsPlaying(state.paused);
        }
      });
    }
  };

  render() {
    if (this.state.spotifyLoaded) {
      return (
        <div className="spotify-player-container d-flex justify-content-between align-items-center">
          <div className="info-container d-flex align-items-center">
            <img
              src={
                this.props.playerService.currentlyPlayingTrack
                  ? SpotifyImageModel.getSmallestImage(this.props.playerService.currentlyPlayingTrack.album.images)
                  : DefaultProfilePicture
              }
              alt="currently playing album"
            />
            <div className="song-info d-flex flex-column">
              <span className="section-text-sm8">
                {this.props.playerService.currentlyPlayingTrack ? this.props.playerService.currentlyPlayingTrack.artists[0].name : 'Artist name'}
              </span>
              <span className="section-text-sm1">
                {this.props.playerService.currentlyPlayingTrack ? this.props.playerService.currentlyPlayingTrack.name : 'Track name'}
              </span>
            </div>
          </div>
          <div className="controls-and-track d-flex flex-column align-items-center">
            <div className="controls d-flex align-items-center justify-content-even">
              <PlayerReplayLast5Seconds
                className={`${!this.props.playerService.isPlaying ? 'disabled' : ''}`}
                onClick={() => this.handleReplayOrForward(-5000)}
              />
              <PlayerPreviousSong onClick={() => this.props.playerService.playPreviousTrack()} />
              {this.props.playerService.isPlaying ? (
                <PlayerPausedFilled onClick={() => this.togglePlayback()} />
              ) : (
                <PlayerPlayFilled onClick={() => this.togglePlayback()} />
              )}
              <PlayerNextSong onClick={() => this.props.playerService.playNextTrack()} />
              <PlayerForward30Seconds
                className={`${!this.props.playerService.isPlaying ? 'disabled' : ''}`}
                onClick={() => this.handleReplayOrForward(30000)}
              />
            </div>
            <div className="track-progress d-flex align-items-center justify-content-between">
              <div className="progress-number section-text-sm9">
                {this.props.playerService.getProgressInDisplayFormat(
                  this.state.dragging || this.props.playerService.progressTimerRef
                    ? this.state.temporaryProgressValue
                    : this.props.playerService.currentProgress,
                )}
              </div>
              <div className="progress-slider-container">
                <SliderWithTooltip
                  className="with-handle"
                  min={1}
                  max={this.props.playerService.currentlyPlayingTrack ? this.props.playerService.currentlyPlayingTrack.duration_ms : 60000}
                  value={
                    this.state.dragging || this.props.playerService.progressTimerRef
                      ? this.state.temporaryProgressValue
                      : this.props.playerService.currentProgress
                  }
                  onChange={this.startDragging}
                  onAfterChange={this.stopDraggingAndJumpToProgressPoint}
                  style={{ width: '500px' }}
                  tipFormatter={value => this.props.playerService.getProgressInDisplayFormat(value)}
                />
              </div>
              <div className="progress-number section-text-sm9">
                {this.props.playerService.currentlyPlayingTrack ? this.props.playerService.currentlyPlayingTrack.getDurationInDisplayFormat() : '00:00'}
              </div>
            </div>
          </div>
          <div className="volume-control d-flex flex-column justify-content align-items-end">
            <div className="volume-invisible"></div>
            <div className="volume-container d-flex align-items-center">
              {this.props.playerService.volume > 0 ? (
                <PlayerVolumeOn onClick={() => this.setVolumeByClick(true)} />
              ) : (
                <PlayerVolumeOff onClick={() => this.setVolumeByClick(false)} />
              )}
              <div className="volume-slider-container">
                <SliderWithTooltip
                  className={`with-handle ${this.props.playerService.volume === 0 ? 'muted' : ''}`}
                  min={0}
                  max={1}
                  step={0.01}
                  defaultValue={this.props.playerService.volume}
                  onAfterChange={this.changeVolume}
                  style={{ width: '150px' }}
                  tipFormatter={value => Math.round(value * 100)}
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else return null;
  }
}
