import React, { useContext } from 'react';
import { AppStatesService, AppStatesServiceContext, getPopUpParams, useStateForServices } from '..';
import { AppStatesEnum, ServiceHOCPropsType, StateForService, TokenModel, TokenObjectType, UserModel } from '../../data';
import { config } from '../../environments/config';

export class AuthService {
  constructor(
    private _appStatesService: AppStatesService,
    private _authenticated: StateForService<boolean>,
    private _headers: StateForService<HeadersInit>,
    private _user: StateForService<UserModel>,
    private _token: StateForService<TokenModel>,
  ) {}

  /* Public methods for getting & setting service state*/

  public get authenticated(): boolean {
    return this._authenticated.value;
  }

  public updateAuthenticated(value: boolean): void {
    this._authenticated.update(value);
  }

  public get GET_REQUEST(): RequestInit {
    return {
      method: 'GET',
      mode: 'cors' as RequestMode,
      headers: this._headers.value,
    };
  }

  public REQUEST_WITH_BODY(type: string, body: any = {}) {
    return {
      method: type,
      mode: 'cors' as RequestMode,
      headers: this._headers.value,
      body: JSON.stringify(body),
    };
  }

  public get headers(): HeadersInit {
    return this._headers.value;
  }

  public updateHeaders(value: HeadersInit): void {
    this._headers.update(value);
  }

  public get user(): UserModel {
    return this._user.value;
  }

  public updateUser(value: UserModel): void {
    this._user.update(value);
  }

  public get token(): TokenModel {
    return this._token.value;
  }

  public updateToken(value: TokenModel): void {
    TokenModel.saveTokenToLocalStorage(value);
    this._token.update(value);
  }

  /* Main Wrapper function for catching Unauthenticated errors */

  /**
   * Function wrapper around all request that is used to check if token sent is valid or not, and captures errorResponse
   * @returns Either a promise of request if authenticated, or calls authorize again if user token expired / is not authenticated
   */
  public requestWrapper = async (requestUri: string, requestInit: RequestInit): Promise<any> => {
    const response: any = await fetch(requestUri, requestInit);
    if (response.status === 401 || (response.error && (response.error === 'invalid_client' || response.error.status === 401))) {
      console.log('Unauthorized call to spotify');
      const authorizeResponse = await this.authorize();
      if (authorizeResponse) {
        requestInit.headers = new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authorizeResponse.access_token}`,
        });
        const responseRetry = await fetch(requestUri, requestInit);
        console.log('Retry', responseRetry);
        return responseRetry;
      }
    } else {
      return response;
    }
  };

  /* Public methods for data manipulations and side effects*/

  /**
   *
   * @param showDialog Boolean which sets Spotify permission dialog on/off => false by default
   * @returns Returns a Promise whether user was successfully authenticated
   */
  public authorize(differentAccount = false): Promise<TokenModel | null> {
    return new Promise((resolve, reject) => {
      const spotifyPopUp: Window = window.open(this._getAuthorizeUri(differentAccount), 'Spotify Login', getPopUpParams())!;

      if (differentAccount) {
        // Hack 1: Loading spotify logout uri via img src
        //this._logoutHack1(spotifyPopUp);
        // Hack 2: Using setTimeout to logout user and redirect to loading page
        //this._logoutHack2(spotifyPopUp);
      }

      const spotifyTimerRef = setInterval(async () => {
        try {
          if ((spotifyPopUp.document && spotifyPopUp.document.URL) || (spotifyPopUp.window && spotifyPopUp.window.document.URL)) {
            const returnUrl = spotifyPopUp.document ? spotifyPopUp.document.URL : spotifyPopUp.window.document.URL;
            if (returnUrl.indexOf(config.spotify.redirectUri) !== -1) {
              const tokenObject = this._extractTokenFromHash(returnUrl, config.spotify.redirectUri);
              const token = new TokenModel(tokenObject);
              const amIAuthenticated = await this.whoAmI(token);
              clearInterval(spotifyTimerRef);
              spotifyPopUp.close();
              resolve(amIAuthenticated);
            }
          }
        } catch (_) {}
      }, 500);
    });
  }

  /**
   * Log out user and that call authorize pop up screen which has Change user option( which is not intuitive but best case for now )
   */
  public async loginWithAnotherAccount(): Promise<any> {
    this.logout();
    this.authorize(true);
  }

  /**
   * Logs user out. Reset auth and playlist data
   */
  public logout(): void {
    this.updateHeaders(new Headers());
    this.updateToken(TokenModel.createEmptyToken());
    this.updateUser(UserModel.createGuest());
    this.updateAuthenticated(false);
  }

  /**
   * Wrapper function that should only execute if user is authenticated. If not, call Spotify login pop up
   * @param callback Function to execute if user is authenticated
   */
  public onlyAuthenticated(callback: Function): void {
    if (this.authenticated) {
      callback();
    } else {
      this.authorize().then(res => {
        callback();
      });
    }
  }

  /**
   * Calls spotify API to get user with token and sets User if gets expected response
   * @param token Expects token model to call
   * @returns Returns a promise with boolean whether User response got expected response or not
   */
  public async whoAmI(token: TokenModel): Promise<TokenModel | null> {
    try {
      if (token.validate()) {
        const userResponse = await fetch(config.spotify.whoAmIUri, {
          method: 'GET',
          mode: 'cors' as RequestMode,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access_token}`,
          },
        });
        const userObject = await userResponse.json();
        const user = new UserModel(userObject);
        let amIAuthenticated;
        if (user.validate()) {
          this.updateUser(user);
          this._saveToken(token);
          this.updateAuthenticated(true);
          amIAuthenticated = token;
        } else {
          amIAuthenticated = null;
        }

        this._appStatesService.updateAppState(AppStatesEnum.USER_CHECKED);
        return amIAuthenticated;
      } else {
        localStorage.removeItem('token');
        throw new Error('Token not valid!');
      }
    } catch (err) {
      localStorage.removeItem('token');
      this._appStatesService.updateAppState(AppStatesEnum.USER_CHECKED);
      return null;
    }
  }

  /* Private functions */
  private _extractTokenFromHash(hash: string, redirectUri: string): TokenObjectType {
    return hash
      .replace(redirectUri, '')
      .substring(1)
      .split('&')
      .reduce((initial: any, item: string) => {
        if (item) {
          var parts = item.split('=');
          initial[parts[0]] = decodeURIComponent(parts[1]);
        }
        return initial;
      }, {});
  }

  /**
   * Local function that is used for saving token. It updates request headers, saves token to local storage and to service.
   * @param token TokenModel to save
   */
  private _saveToken(token: TokenModel): void {
    this.updateHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token.access_token}`,
    });
    this.updateToken(token);
  }

  /**
   * Helper that puts together authorization uri
   * @param showDialog boolean for showing Spotify permission dialog
   * @returns string authorization uri
   */
  private _getAuthorizeUri(showDialog: boolean): string {
    return `${config.spotify.authorizeURL}/authorize?client_id=${config.spotify.clientID}&redirect_uri=${
      config.spotify.redirectUri
    }&scope=${config.spotify.scopes.join('%20')}&response_type=token&show_dialog=${showDialog}`;
  }

  /**
   * Method that calls spotify logout via image src
   * @param spotifyPopUp Window object of opened pop up
   */
  private _logoutHack1(spotifyPopUp: Window) {
    const hackImage = document.createElement('img');
    hackImage.src = 'https://www.spotify.com/logout';
    hackImage.style.height = '1px';
    hackImage.style.width = '1px';
    hackImage.onerror = () => {
      spotifyPopUp.location = this._getAuthorizeUri(false);
    };
  }

  /**
   * Method uses setTimeout to logout user out of spotify and redirect to login after specified interval
   * @param spotifyPopUp Window object of opened pop up
   */
  private _logoutHack2(spotifyPopUp: Window, interval: number = 1000) {
    setTimeout(() => {
      try {
        spotifyPopUp.location = this._getAuthorizeUri(false);
      } catch (_) {}
    }, interval);
  }
}

export const AuthServiceContext = React.createContext<AuthService | undefined>(undefined);

export const AuthServiceHOC = (props: ServiceHOCPropsType) => {
  // Get contexts
  const appStatesService = useContext(AppStatesServiceContext);
  if (!appStatesService) throw new Error('Missing context!');

  const service = new AuthService(
    appStatesService,
    useStateForServices<boolean>(false),
    useStateForServices<HeadersInit>(new Headers()),
    useStateForServices<UserModel>(UserModel.createGuest()),
    useStateForServices<TokenModel>(TokenModel.createEmptyToken()),
  );

  return <AuthServiceContext.Provider value={service}>{props.children}</AuthServiceContext.Provider>;
};
