export const routes = {
  about: '/about',
  contact: '/contact',
  home: '/',
  legal: '/legal',
  login: '/callback',
  logout: '/logout',
  playlistBuilderBase: '/playlist-builder',
  playlistBuilderFull: '/playlist-builder/:playlistID',
  playlistSources: '/playlist-sources',
  privacy: '/privacy',
  termsAndConditions: '/terms-and-conditions',
  wildCard: '*',
};
