import { LocalStorageEnum } from '../../';

export type TokenObjectType = {
  access_token: string;
  expires_in: string;
  token_type: string;
  token_created?: Date;
  error?: any;
};

export class TokenModel {
  public static createEmptyToken(): TokenModel {
    return new TokenModel({
      access_token: '',
      expires_in: '',
      token_type: '',
      token_created: new Date(),
    });
  }

  public static loadTokenFromLocalStorage(): TokenModel {
    const localStorageToken = JSON.parse(localStorage.getItem(LocalStorageEnum.TOKEN) || '{}');
    return new TokenModel(localStorageToken);
  }

  public static saveTokenToLocalStorage(token: TokenModel): void {
    localStorage.setItem(LocalStorageEnum.TOKEN, JSON.stringify(token));
  }

  constructor(tokenObject: TokenObjectType) {
    this.access_token = tokenObject.access_token;
    this.expires_in = tokenObject.expires_in;
    this.token_type = tokenObject.token_type;
    this.token_created = tokenObject.token_created ? new Date(tokenObject.token_created) : new Date();
  }

  public access_token: string;
  public expires_in: string;
  public token_type: string;
  public token_created: Date;

  public validate(): boolean {
    if (!this.access_token || !this.expires_in || !this.token_type || !this._checkIfTokenExpired()) {
      return false;
    } else return true;
  }

  private _checkIfTokenExpired(): boolean {
    let currentTime = new Date();
    let tokenExpiresAt = new Date(this.token_created.getTime());
    tokenExpiresAt.setHours(tokenExpiresAt.getHours() + 1);
    if (tokenExpiresAt.getTime() >= currentTime.getTime()) {
      return true;
    } else return false;
  }
}
