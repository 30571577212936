import React from 'react';
import { Modal } from 'react-bootstrap';
import './index.scss';

type StaticModalWrapperProps = {
  children?: React.ReactChild;
  title: string;
};

export const StaticModalWrapper = (props: StaticModalWrapperProps) => {
  return (
    <div className="static-modal-container">
      <Modal.Header className="title article-text-lg3">{props.title}</Modal.Header>
      <Modal.Body className="content article-text-lg2">{props.children}</Modal.Body>
    </div>
  );
};
