import React from 'react';
import { StaticModalWrapper } from '../index';

export const LegalModal = () => {
  return (
    <StaticModalWrapper title="Legal">
      <>
        Fusce rhoncus risus et pellentesque porta. Phasellus ac orci diam. Praesent quam massa, convallis sit amet condimentum non, tincidunt sed nunc. Vivamus
        molestie orci sed urna vulputate, eget rutrum ligula auctor. Curabitur ut ex fermentum, facilisis ipsum et, maximus tortor. Morbi diam lorem, blandit
        quis egestas sed, suscipit id neque. Sed sagittis lorem sit amet mi sagittis sodales. Nunc interdum condimentum odio, lacinia varius ipsum sagittis
        eget. Integer ultricies condimentum lectus, a venenatis neque ultricies in. Mauris fermentum nunc et scelerisque tempus. Ut nec tellus id nibh lacinia
        eleifend at a purus. Vestibulum ut mattis risus. Nam congue, diam vel aliquam semper, dolor nibh tincidunt justo, sit amet congue mauris ante vitae
        lacus. Nunc lacinia rutrum turpis in venenatis.
      </>
    </StaticModalWrapper>
  );
};
