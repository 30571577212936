export type TrackMetadataObjectType = {
  acousticness: number;
  danceability: number;
  duration_ms: number;
  energy: number;
  instrumentalness: number;
  liveness: number;
  loudness: number;
  key: number;
  speechiness: number;
  valence: number;
  tempo: number;
};

export class TrackMetadataModel {
  public static createEmptyMetadata(): TrackMetadataModel {
    return new TrackMetadataModel({
      acousticness: 0,
      danceability: 0,
      duration_ms: 0,
      energy: 0,
      instrumentalness: 0,
      liveness: 0,
      loudness: 0,
      key: 0,
      speechiness: 0,
      valence: 0,
      tempo: 0,
    });
  }

  constructor(trackObject: TrackMetadataObjectType) {
    this.acousticness = trackObject.acousticness;
    this.danceability = trackObject.danceability;
    this.duration_ms = trackObject.duration_ms;
    this.energy = trackObject.energy;
    this.instrumentalness = trackObject.instrumentalness;
    this.liveness = trackObject.liveness;
    this.loudness = trackObject.loudness;
    this.key = trackObject.key;
    this.speechiness = trackObject.speechiness;
    this.valence = trackObject.valence;
    this.tempo = trackObject.tempo;
  }
  public acousticness: number;
  public danceability: number;
  public duration_ms: number;
  public energy: number;
  public instrumentalness: number;
  public liveness: number;
  public loudness: number;
  public key: number;
  public speechiness: number;
  public valence: number;
  public tempo: number;
}
