import { PlaylistEnums, TrackMetadataModel, TrackModel, FilterTypeEnum, AxisFilterModel, BasicFilterPresetEnum } from '../../';

export type IsofiPlaylistObjectType = {
  id: string;
  name: string;
  owner: string;
  sources: string[];
  tracks: TrackModel[];
  expanded: boolean;
  stats: PlaylistStatsModel;
  filterType: FilterTypeEnum;
  basicPreset?: BasicFilterPresetEnum;
  filterX: AxisFilterModel;
  filterY: AxisFilterModel;
};

export class IsofiPlaylistModel {
  public static createEmptyPlaylist(): IsofiPlaylistModel {
    return new IsofiPlaylistModel(
      PlaylistEnums.EMPTY_NAME,
      PlaylistEnums.EMPTY_NAME,
      'Guest',
      FilterTypeEnum.BASIC,
      new AxisFilterModel('energy', 0, 1),
      new AxisFilterModel('valence', 0, 1),
    );
  }

  public static deserialize(trackObject: IsofiPlaylistObjectType): IsofiPlaylistModel {
    const isofiTrackModel = new IsofiPlaylistModel(
      trackObject.id,
      trackObject.name,
      trackObject.owner,
      trackObject.filterType,
      new AxisFilterModel(trackObject.filterX.metadataKey, trackObject.filterX.minValue, trackObject.filterX.maxValue),
      new AxisFilterModel(trackObject.filterY.metadataKey, trackObject.filterY.minValue, trackObject.filterY.maxValue),
      trackObject.basicPreset,
      trackObject.sources,
      trackObject.tracks,
      trackObject.expanded,
      trackObject.stats,
    );

    if (Array.isArray(trackObject.tracks)) {
      isofiTrackModel.tracks = trackObject.tracks.map((track: any) => {
        const trackModel = new TrackModel(track);
        trackModel.metadata = new TrackMetadataModel(track.metadata);

        return trackModel;
      });
    }

    return isofiTrackModel;
  }

  constructor(
    id: string,
    name: string,
    owner: string,
    filterType: FilterTypeEnum,
    filterX: AxisFilterModel,
    filterY: AxisFilterModel,
    basicPreset: BasicFilterPresetEnum | undefined = undefined,
    sources: string[] = [],
    tracks: TrackModel[] = [],
    expanded: boolean = true,
    stats: PlaylistStatsModel = new PlaylistStatsModel(0, 0, 0),
  ) {
    this.expanded = expanded;
    this.id = id;
    this.name = name;
    this.owner = owner;
    this.sources = sources;
    this.tracks = tracks;
    this.stats = stats;
    this.filterType = filterType;
    this.filterX = filterX;
    this.filterY = filterY;
    this.basicPreset = basicPreset;
  }

  static validateName(name: string, listOfPlaylists: IsofiPlaylistModel[]) {
    if (name === '') {
      return false;
    }

    for (const playlist of listOfPlaylists) {
      if (playlist.name === name) {
        return false;
      }
    }

    return true;
  }

  public id: string;
  public name: string;
  public owner: string;
  public sources: string[];
  public tracks: TrackModel[];
  public expanded: boolean;
  public stats: PlaylistStatsModel;
  public filterType: FilterTypeEnum;
  public basicPreset: BasicFilterPresetEnum | undefined;
  public filterX: AxisFilterModel;
  public filterY: AxisFilterModel;
}

export class PlaylistStatsModel {
  constructor(public numberOfSongs: number, public minutes: number, public seconds: number) {}
}
