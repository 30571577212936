import React from 'react';
import GeneralInteractionClear from '../../assets/icons/generalInteractionsClear2.svg';
import './index.scss';

export type HelpMessagePropsType = {
  closeMessage: Function;
  icon: string;
  message: string;
};

export const HelpMessage = (props: HelpMessagePropsType) => {
  return (
    <div className="help-message-container d-flex justify-content-between">
      <div className="d-flex align-items-center">
        <img src={props.icon} alt="type of help message" />
        <span className="section-text-sm1">{props.message}</span>
      </div>
      <img className="close-message" onClick={() => props.closeMessage()} src={GeneralInteractionClear} alt="close help message" />
    </div>
  );
};
