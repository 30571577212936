import { AlbumModel, ArtistModel, TrackMetadataModel } from '../../';

export class TrackModel {
  constructor(trackObject: SpotifyApi.TrackObjectFull | TrackModel) {
    this.album = new AlbumModel(trackObject.album);
    this.artists = trackObject.artists.map(artist => new ArtistModel(artist));
    this.id = trackObject.id;
    this.name = trackObject.name;
    this.duration_ms = trackObject.duration_ms;
  }

  public album: AlbumModel;
  public artists: ArtistModel[];
  public id: string;
  public metadata: TrackMetadataModel = TrackMetadataModel.createEmptyMetadata();
  public name: string;
  public duration_ms: number;

  public getDurationInDisplayFormat(): string {
    if (this.duration_ms) {
      let duration = '';
      const minutes = Math.floor(this.duration_ms / 1000 / 60);
      const seconds = Math.round((this.duration_ms / 1000) % 60);
      duration += minutes < 10 ? `0${minutes}` : minutes;
      duration += ':';
      duration += seconds < 10 ? `0${seconds}` : seconds;

      return duration;
    } else {
      return '00:00';
    }
  }
}
