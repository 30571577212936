import DefaultProfilePicture from '../../../assets/images/defaultProfilePicture.jpg';
import { AccountTypeEnum, SpotifyImageModel } from '../../';

export type UserObjectType = {
  display_name: string;
  id: string;
  images: SpotifyApi.ImageObject[];
  product: AccountTypeEnum;
};

export class UserModel {
  public static createGuest(): UserModel {
    return new UserModel({
      display_name: 'Guest',
      id: '',
      images: [],
      product: AccountTypeEnum.BASIC,
    });
  }

  constructor(userObject: UserObjectType) {
    this.name = userObject.display_name;
    this.id = userObject.id;
    this.images = userObject.images.length ? userObject.images.map(image => new SpotifyImageModel(image)) : [];
    this.product = userObject.product;
  }

  public name: string;
  public id: string;
  public images: SpotifyImageModel[];
  public product: AccountTypeEnum;

  public get profilePicture(): string {
    if (this.images.length) {
      return SpotifyImageModel.getSmallestImage(this.images);
    } else {
      return DefaultProfilePicture;
    }
  }

  public validate(): boolean {
    if (this.name !== '' && this.id !== '') {
      return true;
    } else return false;
  }
}
