import React from 'react';
import { Redirect } from 'react-router-dom';
import { config } from '../../environments/config';

export type PrivateRouteTypes = {
  authenticated: boolean;
  component: React.FunctionComponent;
};

export const PrivateRoute = (props: PrivateRouteTypes) => {
  let Comp = props.component;
  if (props.authenticated) {
    return <Comp />;
  } else {
    return <Redirect to={config.routes.home} />;
  }
};
