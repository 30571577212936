import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import './index.scss';

export type IsofiPlaylistNameModalContentProps = {
  buttonText: string;
  defaultValue?: string;
  submit: Function;
  labelText: string;
  placeholder: string;
};

export const IsofiPlaylistNameModalContent = (props: IsofiPlaylistNameModalContentProps) => {
  const [playlistName, setPlaylistName] = useState(props.defaultValue ? props.defaultValue : '');
  return (
    <div className="isofi-playlist-name-modal-container">
      <Form onSubmit={event => props.submit(event, playlistName)}>
        <Form.Label className="article-text-lg3">{props.labelText}</Form.Label>
        <Form.Control
          onInput={(event: React.ChangeEvent<HTMLInputElement>) => setPlaylistName(event.target.value)}
          size="lg"
          type="text"
          placeholder={props.placeholder}
          value={playlistName}
        />
        <Button disabled={props.defaultValue === playlistName} type="submit" variant="primary">
          {props.buttonText}
        </Button>
      </Form>
    </div>
  );
};
