import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { Modal } from 'react-bootstrap';
import GeneralInteractionsClear2 from '../../../assets/icons/generalInteractionsClear2.svg';
import { config } from '../../../environments/config';
import { ModalEnums } from '../../../data';
import { AppStatesServiceContext, ModalService } from '../../../services';
import './index.scss';

export type ModalWrapperProps = {
  children: React.ReactChild;
};

export const ModalWrapper = (props: ModalWrapperProps) => {
  const appStatesService = useContext(AppStatesServiceContext);
  if (!appStatesService) throw new Error('Missing context!');

  const router = useHistory();

  const closeModal = (): void => {
    appStatesService.updateModal(ModalEnums.NO_MODAL);
    if (ModalService.checkModalURL(router.location.pathname)) {
      router.push(config.routes.home);
    }
  };

  return (
    <Modal dialogClassName="modal-container modal-width" show={true} onHide={closeModal}>
      {props.children}
      <div className="close-modal">
        <img onClick={closeModal} src={GeneralInteractionsClear2} alt="close modal" />
      </div>
    </Modal>
  );
};
