import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import { AppStatesServiceHOC, AuthServiceHOC, SpotifyProviderServiceHOC, PlayerServiceHOC, PlaylistServiceHOC, TemporaryStatesServiceHOC } from './services';
import './index.scss';

/**
 * Add necessary contexts
 */

ReactDOM.render(
  <AppStatesServiceHOC>
    <AuthServiceHOC>
      <SpotifyProviderServiceHOC>
        <PlaylistServiceHOC>
          <PlayerServiceHOC>
            <TemporaryStatesServiceHOC>
              <App />
            </TemporaryStatesServiceHOC>
          </PlayerServiceHOC>
        </PlaylistServiceHOC>
      </SpotifyProviderServiceHOC>
    </AuthServiceHOC>
  </AppStatesServiceHOC>,
  document.getElementById('root'),
);
