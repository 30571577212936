import { SpotifyImageModel } from '../../';

export class AlbumModel {
  constructor(albumObject: SpotifyApi.AlbumObjectSimplified | AlbumModel) {
    this.id = albumObject.id;
    this.name = albumObject.name;
    this.images = albumObject.images.map(image => new SpotifyImageModel(image));
  }

  public name: string;
  public id: string;
  public images: SpotifyImageModel[];
}
