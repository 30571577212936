import React from 'react';
import './index.scss';

export type InstructionItemPropsType = {
  children?: React.ReactChild;
  classToDisplay: string;
  imgSource: string;
  text: string;
  title: string;
};

export const InstructionItem = (props: InstructionItemPropsType) => {
  return (
    <div className={`instruction-item article-text-lg2 d-flex flex-column`}>
      <div className={`picture-container ${props.classToDisplay}`}>
        <img src={props.imgSource} alt="Instruction default sketch" />
      </div>
      <div className="title">{props.title}</div>
      <div className="content">
        <div className="text-container">{props.text}</div>
        {props.children}
      </div>
    </div>
  );
};
