export enum FilterTypeEnum {
  BASIC = 'BASIC',
  ADVANCED = 'ADVANCED',
}

export enum BasicFilterPresetEnum {
  DISTRACTED_FOCUSED = 'distracted_focused',
  CONFUSION_PEACE = 'confusion_peace',
  HOPELESS_HOPEFUL = 'hopeless-hopeful',
}
