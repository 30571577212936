import React from 'react';
import { StaticModalWrapper } from '../index';

export const ContactModal = () => {
  return (
    <StaticModalWrapper title="Contact us">
      <>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam posuere venenatis risus quis posuere. Curabitur in laoreet tellus. Pellentesque habitant
        morbi tristique senectus et netus et malesuada fames ac turpis egestas. Phasellus eget ultricies metus. Proin tincidunt iaculis nulla, quis finibus sem
        tristique fermentum. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec feugiat semper lorem non
        maximus.
      </>
    </StaticModalWrapper>
  );
};
