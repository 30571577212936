import React from 'react';
import { StaticModalWrapper } from '../index';

export const PrivacyModal = () => {
  return (
    <StaticModalWrapper title="Privacy">
      <>
        Duis vehicula sapien imperdiet, feugiat enim vitae, finibus est. Integer sed est non libero convallis sodales. Morbi interdum urna tortor. Curabitur in
        tortor accumsan, tristique nisl vel, ultricies lorem. Nunc non sem nulla. Etiam ut sem pharetra, luctus nisi eu, tristique nulla. Ut ullamcorper tortor
        a sem iaculis iaculis. Suspendisse vulputate nisl at mauris rutrum posuere. Nullam ut tincidunt ligula. Ut eu ipsum maximus, varius massa lacinia,
        pretium lectus. Aliquam pretium, nibh sit amet hendrerit auctor, lorem sem accumsan orci, sit amet euismod magna ligula eget eros.
      </>
    </StaticModalWrapper>
  );
};
