import React from 'react';
import { Button } from 'react-bootstrap';
import { History } from 'history';
import { config } from '../../environments/config';
import './index.scss';

export type Page404PropTypes = {
  history: History;
};

export const Page404 = (props: Page404PropTypes) => {
  const reDirect = () => {
    props.history.push(config.routes.home);
  };
  return (
    <div className="page-404-container d-flex justify-content-center align-items-center">
      <div className="page-404-holder d-flex flex-column">
        <div className="page-404-title">Page not found!</div>
        <div className="page-404-button">
          <Button variant="primary" className="section-text-sm1" onClick={reDirect}>
            Home
          </Button>
        </div>
      </div>
    </div>
  );
};
