import { TrackModel, TrackMetadataModel, BackgroundFetchStateEnum } from '../../';

export type PlaylistObjectType = {
  id: string;
  description: string;
  images: SpotifyApi.ImageObject[];
  loading?: boolean;
  name: string;
  tracks?: TrackModel[];
  owner: string;
};

export class SpotifyPlaylistModel {
  static deserialize(playlistObject: PlaylistObjectType): SpotifyPlaylistModel {
    const playlist = new SpotifyPlaylistModel(playlistObject);
    playlist.loading = false;

    if (Array.isArray(playlistObject.tracks)) {
      playlistObject.tracks.map((track: any) => {
        const trackModel = new TrackModel(track);
        trackModel.metadata = new TrackMetadataModel(track.metadata);

        return trackModel;
      });
    } else {
      playlist.tracks = [];
    }
    playlist.tracks = Array.isArray(playlistObject.tracks) ? playlistObject.tracks : [];

    return playlist;
  }

  constructor(playlistObject: PlaylistObjectType | SpotifyApi.PlaylistBaseObject) {
    this.id = playlistObject.id;
    this.description = playlistObject.description ? playlistObject.description : '';
    this.images = playlistObject.images;
    this.loading = false;
    this.name = playlistObject.name;
    this.tracks = [];

    if (typeof playlistObject.owner === 'string') {
      this.owner = playlistObject.owner;
    } else {
      this.owner = playlistObject.owner.id;
    }
  }

  public description: string;
  public id;
  public images: SpotifyApi.ImageObject[];
  public loading: boolean;
  public name: string;
  public tracks: TrackModel[];
  public owner: string;
}

export class SpotifyBasicInfoModel {
  constructor(public name: string, public description: string) {}
}

export type BackgroundPlaylistFetchType = {
  initiated: boolean;
  ids: string[];
};

export class BackgroundPlaylistFetchModel {
  constructor(public state: BackgroundFetchStateEnum, public ids: string[] = []) {
    this.state = state;
    this.ids = ids;
  }
}
